<template>
    <div id="path">
        <!--## Blocco tabella miei ultimi percorsi ##-->
        <div class="card">
            <div class="card-body">
                <div class="float-right mb-2 ml-2">
                    <router-link :to="{ name: routesNames.PATH }" class="btn btn-danger btn-sm btn-rounded"><i class="mdi mdi-delete-alert-outline"></i> {{$t('generale.annulla')}}</router-link>
                </div>

                <h5 class="header-title mb-4 text-uppercase"><i class="mdi mdi-file-table-box-multiple-outline font-size-24 mr-1 align-middle"></i> {{$t('addPath.aggiungiNuovaMateria')}}</h5>

                <div class="row">
                    <div class="col-sm-12">
                        <template v-if="allPaths.length > 0">
                            <p class="card-title-desc">{{$t('addPath.effettuaUnaRicercaOppureFiltraLeMaterie')}}</p>
                            <table id="datatable" class="table table-bordered dt-responsive" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                            <thead>
                            <tr>
                                <th style="width: 200px!important">{{$t('addPath.argomento')}}</th>
                                <th>{{$t('addPath.sottoargomenti')}}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                <template v-for="(path, i) in allPaths">
                                    <tr :key="i" :class="(path.premium && isDemoUser) ? 'bg-premium' : ''">
                                        <th scope="row" :class="[((path.premium && isDemoUser) ? 'text-white' : ''), 'text-break verticalCenter']">
                                            {{path.argomento}}
                                            <span v-if="path.premium && isDemoUser" class="badge badge-danger">Premium</span>
                                        </th>
                                        <td class="pb-0 verticalCenter" style="margin-bottom: .75rem;" :id="'subPathText-'+i">
                                            <div :class="[((path.premium && isDemoUser) ? 'text-white' : ''), 'truncate text-break']" :id="'subPathsText-' + i">
                                                <template v-for="subPath in orderSubPaths(path.sotto_percorsi)">
                                                    {{ printString(subPath, true) }}
                                                </template>
                                            </div>
                                            <div class="py-3 text-center" :id="'divButtonShowMoreText-'+i">
                                                {{isTextEmpty(i)}}
                                                <button type="button" :id="'buttonShowMoreText-'+i" class="btn btn-primary btn-sm" @click="expandOrCollapseSubPath(i)">
                                                    {{ $t('addPath.vediDiPiu') }}
                                                </button>
                                            </div>
                                        </td>
                                        <td class="verticalCenter">
                                            <button v-if="verifyIfPathIsAlreadyAddedByUser(path.id_argomento)" type="button" @click="goToPath(path)" class="btn btn-primary btn-rounded btn-sm btn-block waves-effect waves-light">{{$t('generale.apri')}}</button>
                                            <button v-else type="button" @click="addPath(path)" class="btn btn-primary btn-rounded btn-block btn-sm waves-effect waves-light">{{$t('generale.aggiungi')}}</button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        </template>
                        <template v-else>
                            <div class="text-center">{{$t('paths.nonCiSonoMaterie')}}</div>
                        </template>
                    </div>
                </div>

            </div>
        </div>
        <!--## Fine Blocco tabella miei ultimi concorsi ##-->

        <!-- Modal message premium -->
        <div id="modalMessagePremium" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalMessagePremiumLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalMessagePremiumLabel">{{$t('addPath.modale.messagePremium.titolo')}}</h5>
                    </div>
                    <div id="modal-body-message-premium" class="modal-body">
                        {{ $t('addPath.modale.messagePremium.perUtilizzareMateriaPremium') }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('addPath.modale.messagePremium.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal message premium -->

        <!-- Modal generic error -->
        <div id="modalGenericErrorAddPath" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGenericErrorAddPathLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalGenericErrorAddPathLabel">{{$t('addPath.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-generic-add-path-error" class="modal-body">
                        <p id="message-body-generic-add-path-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal add path error -->

        <!-- Modal add path error -->
        <div id="modalErrorAddPath" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorAddPathLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorAddPathLabel">{{$t('addPath.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-add-path-error" class="modal-body">
                        <p id="message-body-add-path-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.HOME })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-add-path" class="btn btn-primary waves-effect waves-light" @click="verifyUptime()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal add path error -->

        <!-- Modal competitions request error -->
        <div id="modalPathsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPathsRequestsErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalPathsRequestsErrorLabel">{{$t('competitions.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-paths-requests-error" class="modal-body">
                        <p id="message-body-paths-requests-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="button-ok-paths" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.HOME })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-paths" class="btn btn-primary waves-effect waves-light" @click="getUtentePercorsi()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal competitions request error -->

    </div>
</template>

<script>
import $ from 'jquery'
import 'datatables.net-bs4'
import _ from "lodash";
import {MODULES} from "@/store/modules-enum";
import {PATH_GETTERS} from "@/store/path/getters-enum";
import {mapActions, mapGetters} from "vuex";
import {AUTH_GETTERS} from "@/store/auth/getters-enum";
import {routesEnums} from "@/helpers/enums";
import {PATH_ACTIONS} from "@/store/path/actions-enum";
import {getDistanceWith, isTimeChecked, setTimeChecked} from "@/lib/utils";
import {systemTimeDelay} from "@/config";
import {UTILITY_MUTATIONS} from "@/store/utility/mutations-enum";
import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
import {UTILITY_ACTIONS} from "@/store/utility/actions-enum";
import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
import {UTILITY_GETTERS} from "@/store/utility/getters-enum";

const separator = " - "

export default {
    name: "AddPath",
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS, AUTH_GETTERS.PROFILE]),
        ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.ENTITIES, COMPETITION_GETTERS.COMPETITIONS]),
        ...mapGetters(MODULES.PATH, [PATH_GETTERS.PATHS, PATH_GETTERS.USER_PATHS]),
        ...mapGetters(MODULES.UTILITY, [UTILITY_GETTERS.GET_LOCAL_UPTIME]),
        allPaths: function() {
            return this.paths
        }
    },
    data () {
        return {
            routesNames: routesEnums,
            isDemoUser: false,
            dt: null
        }
    },
    mounted() {
        this.backToTop()
        this.verifyUptime()
    },
    methods: {
        ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_ENTITIES, COMPETITION_ACTIONS.GET_COMPETITIONS]),
        ...mapActions(MODULES.PATH, [PATH_ACTIONS.GET_PATHS, PATH_ACTIONS.GET_USER_PATHS, PATH_ACTIONS.GET_PATH, PATH_ACTIONS.SET_SELECTED_PATH]),
        ...mapActions(MODULES.UTILITY, [UTILITY_ACTIONS.GET_UPTIME]),
        ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.GET_USER]),
        verifyUptime () {
            this.$emit('is-components-ready', 'addPath', false)
            const context = this
            this.getUptime()
                .then((res) => {
                    context.verifySystemTime(res.headers.date)
                    if (context.competitions.length < 1 || context.entities.length < 1 || context.paths.length < 1 ) {
                        context.sendAllRequests(false, res.body)
                    } else {
                        if (res.body > context.getLocalUptime) {
                            context.sendAllRequests(false, res.body)
                        } else {
                            context.sendAllRequests(true)
                        }
                    }
                })
                .catch(() => {
                    context.sendAllRequests(false)
                })
                .finally(() => {
                    context.getUtentePercorsi()
                    context.init()
                })
        },
        verifySystemTime (headersDate) {
            if (!isTimeChecked()) {
                setTimeChecked(true);
                const timeDiff = getDistanceWith(headersDate)
                if (timeDiff > systemTimeDelay) {
                    $('#modalTime').modal('show')
                }
            }
        },
        sendAllRequests (onlyUser, uptime) {
            this.isRequestsReady('sendAllRequests', false)
            $('#modalErrorAddPath').modal('hide')
            const context = this
            $('#button-retry-add-path').attr('disabled', 'disabled')
            const setupPromise = context.getUser(true).then(res => {
                if (res.userCleared || !onlyUser) {
                    return Promise.all([
                        context.getCompetitions(context),
                        context.getEntities(context),
                        context.getPaths(context)
                    ])
                }
            })
            setupPromise
                .then(() => {
                    if (uptime !== undefined && uptime !== null) {
                        this.$store.commit(MODULES.UTILITY + "/" + UTILITY_MUTATIONS.SET_UPTIME, uptime)
                    }
                })
                .catch(() => {
                    context.$emit('is-components-ready', 'addPath', true)
                    $('#button-retry-add-path').removeAttr('disabled')
                    $('#modalErrorAddPath').data('bs.modal', null)
                    $('#modalErrorAddPath').modal({backdrop: 'static', keyboard: false})
                    $('#message-body-add-path-error').html(context.$t('layouts.main.modale.errore.richieste'))
                })
        },
        init () {
            const context = this
            this.isDemoUser = (this.profile.livello >= 2)
            $( document ).ready(function() {
                if (context.dt !== null) {
                    context.dt.destroy()
                }
                context.dt = $('#datatable').DataTable({
                    language: {
                        url: '//cdn.datatables.net/plug-ins/1.10.21/i18n/Italian.json'
                    }
                });
                $(".dataTable").parent().addClass("overflow-table");
            })
        },
        printString (sotto_percorso, init) {
            let stringToPrint = (sotto_percorso.nome !== ""
                && sotto_percorso.nome !== 'Non ancora classificati'
                && sotto_percorso.nome !== 'Non classificati'
                && sotto_percorso.nome !== 'Non ancora classificate'
                && sotto_percorso.nome !== 'Non ancora classifcate'
                && sotto_percorso.nome !== 'Non ancora classficati'
                && sotto_percorso.nome !== 'Non anccora classificati'
                && sotto_percorso.nome !== 'Non anora classificati'
                && sotto_percorso.nome !== 'Non ancora classificat'
                && sotto_percorso.nome !== 'Ancora non classificati'
                && sotto_percorso.nome !== 'Non anora classifcate'
                && sotto_percorso.nome !== 'Non ancora clsssificati'
                && sotto_percorso.nome !== 'Non acora classificati'
                && sotto_percorso.nome !== 'Non ancora classifciati'
                && sotto_percorso.nome !== 'Non anora classifcati'
            ) ? ((init) ? (separator + sotto_percorso.nome || undefined) : (sotto_percorso.nome || undefined)) : ''
            const arraySottoPercorso = this.orderSubPaths(sotto_percorso.sotto_percorsi)
            if (arraySottoPercorso.length > 0) {
                arraySottoPercorso.forEach(sp => {
                    if (sp.nome !== 'Non ancora classificati' && sp.nome !== 'Non classificati' && sp.nome !== 'Non ancora classificate') {
                        if (stringToPrint === undefined) {
                            stringToPrint = this.printString(sp, false)
                        }
                        else {
                            stringToPrint = stringToPrint + separator + this.printString(sp, false)
                        }
                    }
                })
            }
            return stringToPrint
        },
        orderSubPaths (subPaths) {
            return _.orderBy(subPaths, ['id_sotto_percorso'], ['asc'])
        },
        addPath (path) {
            if (path.premium && this.profile.livello >= 2 && this.profile.livello < 502) {
                $('#modalMessagePremium').modal('show')
            } else {
                this.$emit('is-components-ready', 'addPath', false)
                const context = this
                this.getPath({
                    idPercorso: (path.id_argomento !== null) ? path.id_argomento : context.idArgomento,
                    opts: null
                })
                    .then(() => {
                        context.getUtentePercorsi(path)
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'addPath', true)
                        $('#modalGenericErrorAddPath').modal('show')
                        $('#message-body-generic-add-path-error').html(context.$t('addPath.modale.errore.aggiungiPercorso'))
                    })
            }
        },
        expandOrCollapseSubPath (index) {
            const context = this
            if ($('#subPathsText-' + index).hasClass('truncate')) {
                $('#subPathsText-' + index).removeClass('truncate')
                $('#buttonShowMoreText-' + index).html(context.$t('addPath.vediDiMeno'))
            } else {
                $('#subPathsText-' + index).addClass('truncate')
                $('#buttonShowMoreText-' + index).html(context.$t('addPath.vediDiPiu'))
            }
        },
        isTextEmpty(index) {
            $(document).ready(() => {
                if (document.getElementById('subPathsText-' + index) !== null) {
                    if (document.getElementById('subPathsText-' + index).innerText === '' || document.getElementById('subPathsText-' + index).innerText.length <= 138) {
                        $('#subPathText-' + index).removeClass('pb-0')
                        $('#divButtonShowMoreText-' + index).hide()
                    }
                }
            })
        },
        verifyIfPathIsAlreadyAddedByUser (id_argomento) {
            if (id_argomento !== null && id_argomento !== undefined && id_argomento !== '') {
                return this.filterPathsAddedByUser(id_argomento)
            }
            return false
        },
        filterPathsAddedByUser(filter) {
            const pathAddedByUser = _.filter(this.userPaths, function (userPath) {
                return userPath.id_argomento === filter
            })
            return pathAddedByUser.length > 0;
        },
        goToPath (path) {
            if (path.premium && this.profile.livello >= 2 && this.profile.livello < 502) {
                $('#modalMessagePremium').modal('show')
            } else {
                const context = this
                this.setSelectedPath(path.id_argomento)
                    .then(() => {
                        this.$router.push({ name: routesEnums.PATH_DETAIL_WRAPPER })
                    })
                    .catch(() => {
                        context.$emit('is-components-ready', 'addPath', true)
                        $('#modalGenericErrorAddPath').modal('show')
                        $('#message-body-generic-add-path-error').html(context.$t('addPath.modale.errore.percorsiUtente'))
                    })
            }
        },
        getUtentePercorsi (path) {
            this.isRequestsReady('getUserPaths', false)
            this.$emit('is-components-ready', 'addPath', false)
            const context = this
            this.getUserPaths()
                .then(() => {
                    context.$emit('is-components-ready', 'addPath', true)
                    if (path !== undefined) {
                        context.goToPath(path)
                    }
                })
                .catch(() => {
                    context.$emit('is-components-ready', 'addPath', true)
                    $('#button-retry-add-path').removeAttr('disabled')
                    $('#modalPathsRequestsError').data('bs.modal', null)
                    $('#modalPathsRequestsError').modal({backdrop: 'static', keyboard: false})
                    $('#message-body-paths-requests-error').html(context.$t('addPath.modale.errore.percorsiUtente'))
                })
        },
        backToTop () {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        setRequests () {
            this.requests = [
                {
                    label: 'sendAllRequests',
                    isComplete: true
                },
                {
                    label: 'getUserPaths',
                    isComplete: true
                }
            ]
        },
        isRequestsReady (label, state) {
            const request = _.find(this.requests, (r) => r.label === label)
            if (request !== undefined) {
                request.isComplete = state
            }
            const isRequestPending = _.find(this.requests, (r) => r.isComplete === false)
            if (isRequestPending === undefined) {
                this.$emit('is-components-ready', 'addPath', true)
            }
        }
    }
}
</script>

<style scoped lang="css">
    @import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
    @import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
</style>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        @media (max-width: 1279px) {
            .overflow-table {
                overflow: auto;
            }
        }
    }
</style>